import React, { useState, useEffect } from 'react';
import './PropertyInformation.css';
import { FaDollarSign, FaPercentage, FaBuilding, FaRulerCombined } from 'react-icons/fa';

interface PropertyInformationProps {
  formData: any;
  handleChange: (newData: object) => void;
  nextStep: () => void;
  prevStep: () => void;
}

const PropertyInformation: React.FC<PropertyInformationProps> = ({ formData, handleChange, nextStep, prevStep }) => {
  const [localFormData, setLocalFormData] = useState(formData);
  const [occupancyError, setOccupancyError] = useState('');

  useEffect(() => {
    setLocalFormData(formData);
  }, [formData]);

  const debouncedHandleChange = (newData: object) => {
    setLocalFormData((prevData: typeof formData) => ({ ...prevData, ...newData }));
    setTimeout(() => handleChange(newData), 300);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    let numValue = ['estimatedValue', 'buildingSquareFootage', 'landSquareFootage'].includes(name)
      ? value.replace(/,/g, '')
      : value;

    if (name === 'occupancy') {
      const occupancyValue = parseFloat(numValue);
      if (occupancyValue > 100) {
        setOccupancyError('Occupancy cannot exceed 100%');
        numValue = '100';
      } else {
        setOccupancyError('');
      }
    }

    debouncedHandleChange({ [name]: numValue });
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    debouncedHandleChange({ [e.target.name]: e.target.value });
  };

  const formatNumber = (num: number) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <div className="property-information-container">
      <h2 className="form-title">Subject Property Information</h2>
      
      <div className="form-section">
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="estimatedValue">
              <FaDollarSign /> Estimated Value
            </label>
            <div className="input-with-icon">
              <span className="input-icon">$</span>
              <input
                type="text"
                id="estimatedValue"
                name="estimatedValue"
                value={formatNumber(Number(localFormData.estimatedValue) || 0)}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="valueEstimateSource">Value Estimate Source</label>
            <select
              id="valueEstimateSource"
              name="valueEstimateSource"
              value={localFormData.valueEstimateSource || ''}
              onChange={handleSelectChange}
            >
              <option value="">Select source</option>
              <option value="appraisal">Appraisal</option>
              <option value="estimate">Estimate</option>
              <option value="salesPrice">Sales Price (if purchase)</option>
            </select>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="occupancy">
              <FaPercentage /> Occupancy
            </label>
            <div className="input-with-icon">
              <span className="input-icon">%</span>
              <input
                type="number"
                id="occupancy"
                name="occupancy"
                value={localFormData.occupancy || ''}
                onChange={handleInputChange}
                min="0"
                max="100"
              />
            </div>
            {occupancyError && <div className="error-message">{occupancyError}</div>}
          </div>
          <div className="form-group">
            <label htmlFor="numberOfUnits">
              <FaBuilding /> Number of Units
            </label>
            <input
              type="number"
              id="numberOfUnits"
              name="numberOfUnits"
              value={localFormData.numberOfUnits || ''}
              onChange={handleInputChange}
              min="0"
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="buildingSquareFootage">
              <FaRulerCombined /> Building Sq. Footage
            </label>
            <input
              type="text"
              id="buildingSquareFootage"
              name="buildingSquareFootage"
              value={formatNumber(Number(localFormData.buildingSquareFootage) || 0)}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="landSquareFootage">
              <FaRulerCombined /> Land Sq. Footage
            </label>
            <input
              type="text"
              id="landSquareFootage"
              name="landSquareFootage"
              value={formatNumber(Number(localFormData.landSquareFootage) || 0)}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>

      <div className="button-group">
        <button onClick={prevStep} className="button secondary">Back</button>
        <button onClick={nextStep} className="button primary">Next</button>
      </div>
    </div>
  );
};

export default PropertyInformation;