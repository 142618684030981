import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { getBrokerOverview } from '../Managers/APIManager';
import { FaFileAlt, FaCheckCircle, FaHourglassHalf, FaUsers, FaDollarSign, FaTimesCircle } from 'react-icons/fa';
import './GeneralOverview.css';

interface OverviewData {
  totalRequests: number;
  approvedRequests: number;
  rejectedRequests: number;
  inProgressRequests: number;
  totalCustomers: number;
  totalCompletedLoanValue: number;
  totalInProgressLoanValue: number;
  topCustomers: {
    _id: string;
    totalLoanValue: number;
  }[];
}

interface StatCardProps {
  title: string;
  value: string | number;
  icon: React.ElementType;
}

const StatCard: React.FC<StatCardProps> = ({ title, value, icon: Icon }) => (
  <motion.div
    className="go-card"
    whileHover={{ scale: 1.05 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <div className="go-card-icon">
      <Icon />
    </div>
    <div className="go-card-content">
      <h3 className="go-card-title">{title}</h3>
      <p className="go-card-value">{value}</p>
    </div>
  </motion.div>
);

const GeneralOverview: React.FC = () => {
  const [overviewData, setOverviewData] = useState<OverviewData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchOverviewData = async () => {
      try {
        setIsLoading(true);
        const response = await getBrokerOverview();
        setOverviewData(response);
        setError(null);
      } catch (error) {
        console.error('Error fetching overview data:', error);
        setError('Failed to load overview data. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchOverviewData();
  }, []);

  if (isLoading) {
    return <div className="go-loading">Loading...</div>;
  }

  if (error) {
    return <div className="go-error">{error}</div>;
  }

  if (!overviewData) {
    return <div className="go-no-data">No data available.</div>;
  }

  return (
    <div className="go-container">
      <h2 className="go-title">General Overview</h2>
      <div className="go-grid">
        <StatCard title="Total Mortgage Requests" value={overviewData.totalRequests || 0} icon={FaFileAlt} />
        <StatCard title="Approved Requests" value={overviewData.approvedRequests || 0} icon={FaCheckCircle} />
        <StatCard title="Rejected Requests" value={overviewData.rejectedRequests || 0} icon={FaTimesCircle} />
        <StatCard title="In-Progress Requests" value={overviewData.inProgressRequests || 0} icon={FaHourglassHalf} />
        <StatCard title="Total Customers" value={overviewData.totalCustomers || 0} icon={FaUsers} />
        <StatCard
          title="Total Completed Loan Value"
          value={`$${Math.round(overviewData.totalCompletedLoanValue || 0).toLocaleString('en-US')}`}
          icon={FaDollarSign}
        />
        <StatCard
          title="Total In-Progress Loan Value"
          value={`$${Math.round(overviewData.totalInProgressLoanValue || 0).toLocaleString('en-US')}`}
          icon={FaDollarSign}
        />
      </div>
    </div>
  );
};

export default GeneralOverview;