import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label, Input, Button, Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import { FaPlus, FaTrash, FaDollarSign } from 'react-icons/fa';
import './PersonalFinancialStatement.css';

interface FinancialItem {
  _id?: string;
  type: string;
  description: string;
  value?: number;  // For assets
  amount?: number; // For liabilities
}

interface PersonalFinancialStatementProps {
  formData: {
    assets?: FinancialItem[];
    liabilities?: FinancialItem[];
    cashLiquidity?: number;
  };
  handleChange: (newData: Partial<PersonalFinancialStatementProps['formData']>) => void;
  nextStep: () => void;
  prevStep: () => void;
}

const CurrencyInput: React.FC<{
  value: number | undefined;
  onChange: (value: number) => void;
  placeholder?: string;
}> = ({ value, onChange, placeholder = "0" }) => {
  const [localValue, setLocalValue] = useState(value?.toString() || '');

  useEffect(() => {
    setLocalValue(value?.toString() || '');
  }, [value]);

  const formatValue = (val: string): string => {
    const parts = val.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join('.');
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.replace(/[^0-9.]/g, '');
    setLocalValue(rawValue);
  };

  const handleBlur = () => {
    const numericValue = parseFloat(localValue) || 0;
    onChange(numericValue);
    setLocalValue(formatValue(numericValue.toFixed(2)));
  };

  return (
    <div className="input-group">
      <div className="input-group-prepend">
        <span className="input-group-text"><FaDollarSign /></span>
      </div>
      <Input
        type="text"
        value={formatValue(localValue)}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={placeholder}
      />
    </div>
  );
};

const FinancialItemForm: React.FC<{
  item: FinancialItem;
  onChange: (id: string | undefined, field: keyof FinancialItem, value: string | number) => void;
  onRemove: (id: string | undefined) => void;
  types: string[];
  isAsset: boolean;
}> = ({ item, onChange, onRemove, types, isAsset }) => {
  const [localValue, setLocalValue] = useState((isAsset ? item.value : item.amount)?.toString() || '');

  useEffect(() => {
    setLocalValue((isAsset ? item.value : item.amount)?.toString() || '');
  }, [item, isAsset]);

  const handleValueChange = (value: number) => {
    onChange(item._id, isAsset ? 'value' : 'amount', value);
  };

  return (
    <Row className="mb-3">
      <Col md={3}>
        <Input
          type="select"
          value={item.type}
          onChange={(e) => onChange(item._id, 'type', e.target.value)}
        >
          <option value="">Select Type</option>
          {types.map((type) => (
            <option key={type} value={type}>{type}</option>
          ))}
        </Input>
      </Col>
      <Col md={4}>
        <Input
          type="text"
          placeholder="Description"
          value={item.description}
          onChange={(e) => onChange(item._id, 'description', e.target.value)}
        />
      </Col>
      <Col md={4}>
        <CurrencyInput
          value={parseFloat(localValue) || 0}
          onChange={handleValueChange}
        />
      </Col>
      <Col md={1}>
        <Button color="danger" onClick={() => onRemove(item._id)}><FaTrash /></Button>
      </Col>
    </Row>
  );
};

const PersonalFinancialStatement: React.FC<PersonalFinancialStatementProps> = ({ formData, handleChange, nextStep, prevStep }) => {
  const [assets, setAssets] = useState<FinancialItem[]>(formData.assets || []);
  const [liabilities, setLiabilities] = useState<FinancialItem[]>(formData.liabilities || []);
  const [cashLiquidity, setCashLiquidity] = useState(formData.cashLiquidity || 0);

  useEffect(() => {
    handleChange({ assets, liabilities, cashLiquidity });
  }, [assets, liabilities, cashLiquidity, handleChange]);

  const updateFinancialItem = (
    items: FinancialItem[], 
    setItems: React.Dispatch<React.SetStateAction<FinancialItem[]>>,
    isAsset: boolean
  ) => (id: string | undefined, field: keyof FinancialItem, value: string | number) => {
    setItems(prevItems => 
      prevItems.map(item => {
        if (item._id === id) {
          if (field === 'value' || field === 'amount') {
            return { ...item, [isAsset ? 'value' : 'amount']: Number(value) };
          }
          return { ...item, [field]: value };
        }
        return item;
      })
    );
  };


  const removeFinancialItem = (
    items: FinancialItem[], 
    setItems: React.Dispatch<React.SetStateAction<FinancialItem[]>>
  ) => (id: string | undefined) => {
    setItems(prevItems => prevItems.filter(item => item._id !== id));
  };

  const addFinancialItem = (
    setItems: React.Dispatch<React.SetStateAction<FinancialItem[]>>,
    isAsset: boolean
  ) => () => {
    const newItem: FinancialItem = { 
      type: '', 
      description: '', 
      [isAsset ? 'value' : 'amount']: 0 
    };
    setItems(prevItems => [...prevItems, newItem]);
  };

  const calculateTotal = (items: FinancialItem[], isAsset: boolean): number => 
    items.reduce((sum, item) => sum + (isAsset ? (item.value || 0) : (item.amount || 0)), 0);

  const formatCurrency = (value: number): string => 
    value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });

  return (
    <div className="personal-financial-statement">
      <h2>Personal Financial Statement</h2>
      <Card className="mb-4">
        <CardHeader>
          <h3>Assets</h3>
          <Button color="primary" onClick={addFinancialItem(setAssets, true)}><FaPlus /> Add Asset</Button>
        </CardHeader>
        <CardBody>
          {assets.map((asset) => (
            <FinancialItemForm
              key={asset._id || asset.type + asset.description}
              item={asset}
              onChange={updateFinancialItem(assets, setAssets, true)}
              onRemove={removeFinancialItem(assets, setAssets)}
              types={['Cash', 'Investments', 'Real Estate', 'Vehicles', 'Other']}
              isAsset={true}
            />
          ))}
        </CardBody>
      </Card>
      <Card className="mb-4">
        <CardHeader>
          <h3>Liabilities</h3>
          <Button color="primary" onClick={addFinancialItem(setLiabilities, false)}><FaPlus /> Add Liability</Button>
        </CardHeader>
        <CardBody>
          {liabilities.map((liability) => (
            <FinancialItemForm
              key={liability._id || liability.type + liability.description}
              item={liability}
              onChange={updateFinancialItem(liabilities, setLiabilities, false)}
              onRemove={removeFinancialItem(liabilities, setLiabilities)}
              types={['Mortgage', 'Car Loan', 'Credit Card', 'Student Loan', 'Other']}
              isAsset={false}
            />
          ))}
        </CardBody>
      </Card>
      <Card className="mb-4">
        <CardHeader>
          <h3>Liquidity</h3>
        </CardHeader>
        <CardBody>
          <FormGroup>
            <Label for="cashLiquidity">Cash/Cash Equivalents</Label>
            <CurrencyInput
              value={cashLiquidity}
              onChange={setCashLiquidity}
            />
          </FormGroup>
        </CardBody>
      </Card>
      <Card className="mb-4">
        <CardHeader>
          <h3>Summary</h3>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={6}>
              <p><strong>Total Assets:</strong> {formatCurrency(calculateTotal(assets, true))}</p>
              <p><strong>Total Liabilities:</strong> {formatCurrency(calculateTotal(liabilities, false))}</p>
            </Col>
            <Col md={6}>
              <p><strong>Net Worth:</strong> {formatCurrency(calculateTotal(assets, true) - calculateTotal(liabilities, false))}</p>
              <p><strong>Liquidity:</strong> {formatCurrency(cashLiquidity)}</p>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <div className="button-group">
        <Button color="secondary" onClick={prevStep}>Back</Button>
        <Button color="primary" onClick={nextStep}>Next</Button>
      </div>
    </div>
  );
};

export default PersonalFinancialStatement;